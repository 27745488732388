/*------------------------------------*\
    font-family, filename(no extension), style, weight
\*------------------------------------*/

/*@include font-face('CircularStd', 'CircularStd-Book', 'normal', '400');*/

/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * www.sdmx.no
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2017
 */

@include font-face('CircularStd', 'circular-std/lineto-circular-pro-book', 'normal', '400');
@include font-face('CircularStd', 'circular-std/lineto-circular-pro-bold', 'normal', '700');
@include font-face('CircularStd', 'circular-std/lineto-circular-pro-black', 'normal', '900');

/*------------------------------------*\
    #TYPE
\*------------------------------------*/

$font-sans: 'CircularStd', 'Helvetica', 'Arial', sans-serif;
$font-serif: 'Georgia', 'Times New Roman', Times, serif;

$font-primary: $font-sans;
$font-secondary: $font-serif;

/*------------------------------------*\
    $FONT-SIZES
\*------------------------------------*/

$font-size--xxxsmall: (
    null : 12px,
    md   : 12px
);

$font-size--xxsmall: (
    null : 14px,
    md   : 14px
);

$font-size--xsmall: (
    null : 16px,
    md   : 16px
);

$font-size--small: (
    null : 18px,
    md   : 18px
);

$font-size--regular: (
    null : 18px,
    md   : 20px,
    lg   : 22px
);

$font-size--medium: (
    null : 20px,
    md   : 24px,
    lg   : 26px
);

$font-size--large: (
    null : 26px,
    md   : 30px,
    lg   : 36px
);

$font-size--xlarge: (
    null : 32px,
    md   : 52px,
    lg   : 64px
);

$font-size--xxlarge: (
    null : 36px,
    md   : 58px,
    lg   : 80px
);

$font-size--xxxlarge: (
    null : 58px,
    md   : 72px,
    lg   : 100px
);

/*------------------------------------*\
    $font-weight
\*------------------------------------*/

$font-weight--bold          : 700;
$font-weight--semibold      : 600;
$font-weight--medium        : 500;
$font-weight--normal        : 400;
$font-weight--thin          : 300;
