.nav-site {
  &__list {
    font-size: inherit;
  }
  &__item {
    display: inline-block;
    margin-right: $spacing;

    @include mq(lg) {
      margin-left: $spacing;
      margin-right: 0;
    }
  }
  &__link {
    color: $color-nnkm-yellow;
    font-weight: 700;
  }
}