.view-news-frontpage-block {
  .views-row-box {
    background: #ffffff;
    border-bottom: 2px solid #e2e2e2;
  }
  .view-content {
    @include mq(xs) {
      display: flex;
      flex-flow: row wrap;
      margin: 0 (-$spacing--small) 0;
    }
  }
  .views-row {
    width: 100%;

    @include mq(xs) {
      padding: 0 $spacing--small $spacing--large $spacing--small;
      flex-basis: 50%;
    }
    @include mq(md) {
      padding: 0 $spacing--small $spacing--large $spacing--small;
      flex-basis: 33%;
    }
  }
  .views-field-field-image img {
    display: block;
    /* Upscale images in doubt to take the full width */
    width: 100%;
  }
  .views-field-title, .views-field-body, .views-field-readmore, .views-field-created {
    padding-left: $spacing;
    padding-right: $spacing;
  }
  .views-field-title {
    @include font-size($font-size--small);
    font-weight: bold;
    line-height: 1.192em;
    margin: 0;
    padding-top: 0.692em;
    padding-bottom: 0.423em;
  }
  .views-field-body {
    @include font-size($font-size--xsmall);
    line-height: 1.286em;
    margin: 0 0 0.714em 0;

    p {
      margin: 0
    }
    * {
      font-size: inherit !important;
    }
  }
  .views-field-created {
    background: url("../../nnkm/images/icon-clock-black.png") no-repeat 0 50%;
    @include font-size($font-size--xxxsmall);
    font-weight: 700;
    margin: 0 0 0 1.538em;
    padding: 1em 0 1em 1.923em
  }
  .views-field-readmore {
    @include font-size($font-size--xsmall);
    font-weight: bold;
    margin: 0 0 1.538em 0;

    a {
      background: url("../../nnkm/images/arrow-right-black.png") no-repeat scroll 0 50% transparent;
      float: left;
      padding: 0 0 0 1.5em;
    }
  }
}

/* Hide andre nyheter block */
.pane-views-news-block-1 {
  display: none;
}