.animation {

    &__slide-in-from-top {
        animation: slide-in-from-top 0.1s linear both;
    }
    &__slide-out-to-top {
        animation: slide-out-to-top 0.2s linear both;
    }
    &__slide-in-from-right {
        animation: slide-in-from-right 0.2s linear both;
    }
    &__slide-out-to-right {
        animation: slide-out-to-right 0.1s linear both;
    }
    &__slide-in-from-bottom {
        animation: slide-in-from-bottom 0.14s linear both;
    }
    &__slide-out-to-bottom {
        animation: slide-out-to-bottom 0.1s linear both;
    }
    &__slide-in-from-left {
        animation: slide-in-from-left 0.2s linear both;
    }
    &__slide-out-to-left {
        animation: slide-out-to-left 0.1s linear both;
    }

    &__fade-in {
        animation: fade-in 0.2s linear both;
    }

    &__fade-out {
        animation: fade-out 0.2s linear both;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes slide-in-from-top {
    0% {
        opacity: 0;
        transform: translateY(-60px);
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-in-from-right {
    0% {
        opacity: 0;
        transform: translateX(400px);
    }
    50% {
        opacity: 0.8;
        transform: translateX(10px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slide-out-to-right {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    50% {
        opacity: 0.2;
        transform: translateX(290px);
    }
    100% {
        opacity: 0;
        transform: translateX(300px);
    }
}

@keyframes slide-in-from-bottom {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }
    50% {
        transform: translateY(5px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-out-to-bottom {
    0% {
        opacity: 1;
        transform: translateY(0px);
    }
    50% {
        transform: translateY(90px);
    }
    100% {
        opacity: 0;
        transform: translateY(100px);
    }
}

@keyframes slide-in-from-left {
    0% {
        opacity: 0;
        transform: translateX(-400px);
    }
    50% {
        opacity: 0.8;
        transform: translateX(-10px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slide-out-to-left {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    50% {
        opacity: 0.2;
        transform: translateX(-290px);
    }
    100% {
        opacity: 0;
        transform: translateX(-300px);
    }
}