.page-wrapper {
  position: relative;
}

.page {
  .child {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
