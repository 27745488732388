/*------------------------------------*\
    #VARS
\*------------------------------------*/

//  Breakpoints
$breakpoints: (
        xs : 360px, // X Small and up
        sm : 540px, // Small and up
        md : 640px, // Medium and up
        lg : 960px, // Large and up
        xl : 1280px, // Extra large and up
        xxl : 1400px // Extra extra large and up
);

// Grids max-width
$grid-width: 1200px;

// Spacing
$unit: 5px;
$spacing--xlarge: $unit * 20;
$spacing--large: $unit * 8;
$spacing: $unit * 4;
$spacing--small: $unit * 2;

// Border-width
$border-width: 1px;

// Easing
$swift-out: cubic-bezier(0, 0, .1, 1);