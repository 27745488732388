/*------------------------------------*\
    #COLOR
\*------------------------------------*/

// Neutral colours
$color-neutral--darkest                     : #000000;
$color-neutral--darker                      : #202020;
$color-neutral--dark                        : #666666;
$color-neutral                              : #959595;
$color-neutral--light                       : #e8e8e8;
$color-neutral--lighter                     : #F7F7F7;
$color-neutral--lightest                    : #F9F9F9;
$color-neutral--transparent                 : rgba(0,0,0,0.1);

$color-light                                : #fff;

// Branding colours
$color-nnkm-green                           : #07944a;
$color-nnkm-yellow                          : #ffd131;
$color-nnkm-blue                            : #0054a6;
$color-nnkm-red                             : #e12a27;

// heading-subed
$color-primary                              : yellow;
$color-secondary                            : blue;

// Specific
$color-action                               : $color-nnkm-yellow;
$color-link                                 : pink;
$color-text                                 : $color-neutral--darker;
$color-active                               : green;
$color-warning                              : red;
