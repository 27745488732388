.bildergalerie-box {
  background: #e6e6e6;
  margin: 2.188em 0 0.625em;
  padding: $spacing;

  .pane-title {
    background: url("../../nnkm/images/icon-media.png") no-repeat -2px 50%;
    color: #333333;
    @include font-size($font-size--xxsmall);
    margin: -0.429em 0 1em 0;
    padding: 0 0 0 37px;
    text-transform: uppercase;
  }
}

.field-name-field-bildergalerie {
  position: relative;

  .cycle-slideshow {
    @include mq(sm) {
      width: 400px;
    }
    margin: 0 auto;
  }
  .controls {
    #prev, #next {
      font-size: 0;
      height: 100%;
      position: absolute;
      text-indent: -10000px;
      top: 0;
      z-index: 100;
      width: 50%;

      &:hover {
        background: none;
      }
    }
    #prev {
      left: 0;

      span {
        background: url("../../nnkm/images/arrow-left-white-galerie.png") no-repeat 0 50% transparent !important;
        float: left;
        height: 100%;
        margin: 0 0 0 8px;

        @include mq-max(sm) {
          margin-left: -15px;
        }
        width: 32px;
      }
    }
    #next {
      right: 0;

      span {
        background: url("../../nnkm/images/arrow-right-white-galerie.png") no-repeat 0 50% transparent;
        float: right;
        height: 100%;
        margin: 0 8px 0 0;

        @include mq-max(sm) {
          margin-right: -15px;
        }
        width: 32px;
      }
    }
  }
}
