.block-locale {
  margin-bottom: $spacing--small;

  .block__title {
    display: none;
  }
  .language-switcher-locale-url {
    color: $color-nnkm-yellow;
    @include font-size($font-size--xsmall);

    @include mq-max(lg) {
      float: right;
    }
    @include mq(lg) {
      text-align: right;
    }

    a {
      color: $color-nnkm-yellow;
    }
    li {
      display: inline-block;
      margin-left: 1em;

      &.active {
        text-decoration: underline;
      }
    }
  }
}