body {
  font-family: $font-primary;
  // font-family: $font-secondary;
  // @include font-size($font-size--regular);
  font-style: normal;
  font-weight: 400;

  color: $color-text;

  line-height: 1.4;

  @include mq(md) {
    // line-height: 1.7;
  }
}

// Headings
// --------------------------------------------------------------------------------- //

h1, h2, h3, h4, h5,
.alpha, .beta, .gamma, .delta, .epsilon {
  position: relative;
  display: inline-block;
  font-family: $font-primary;
  font-weight: 400;

  a {

  }
}

h1,
.alpha {
  display: block;
  margin-bottom: $spacing/2;

  @include mq(md) {
    // margin-bottom: $spacing;
  }

  @include mq(lg) {
    // margin-bottom: $spacing*2;
  }

  small {
    display: block;
    width: 100%;

    margin-bottom: $spacing/2;
    margin-top: $spacing/2;

    @include font-size($font-size--small);
  }

  @include font-size($font-size--medium);
  line-height: 1.2;
  font-weight: 700;
}

h2,
.beta {
  margin-bottom: $spacing/4;
  line-height: 1.3;
  font-weight: 400;

  @include font-size($font-size--large);
  font-weight: 700;

  @include mq(md) {
    margin-bottom: $spacing/2;
  }
}

h3,
.gamma {
  margin-bottom: $spacing/4;

  @include font-size($font-size--medium);
  font-weight: 700;

  @include mq(md) {
    margin-bottom: $spacing/2;
  }
}

h4,
.delta {
  @include font-size($font-size--small);
  font-weight: 700;

  @include mq(md) {

  }
}

h5,
.epsilon {

  @include font-size($font-size--xsmall);
  font-weight: 700;
  text-transform: uppercase;
}

// Paragraphs
// --------------------------------------------------------------------------------- //

p {
  margin-bottom: $spacing;

  @include mq(md) {
    margin-bottom: $spacing + 5;
  }

  @include mq(lg) {
    margin-bottom: $spacing*1.5;
  }

  &:last-child {
    // margin-bottom: 0;
  }
}

// Relative spacing - headings / lists / Paragraphs - add images?
p, ol, ul, figure, .table-wrap {

  & + h2,
  & + h3,
  & + h4 {
    margin-top: $spacing/4;

    @include mq(md) {
      margin-top: $spacing/2;
    }

    @include mq(lg) {
      margin-top: $spacing;
    }
  }
}

// p under h1
.heading-sub {
  margin-bottom: $spacing;

  @include font-size($font-size--medium);
  font-weight: 700;

  @include mq(md) {
    margin-bottom: $spacing*1.5;
  }

  .article-date + & {
    clear: left;
  }
}

.heading-search {
  font-weight: 400;
}

// p intro paragraph on normal articles.
.intro {
  // @include font-size($font-size--medium);
  font-weight: 700;

  &:last-child {
    margin-bottom: 0;
  }
}

.excerpt {
  // font-family: $font-primary;
}

// Dropcap, on first line of feature articles only
.article-feature .first-paragraph:first-letter {
  float: left;

  padding: 0 0 0 0;
  margin-right: 10px;

  font-family: $font-secondary;
  font-size: 56px;
  font-weight: $font-weight--medium;
  line-height: 0.9;
  color: $color-neutral--darker;

  @include mq(md) {
    font-size: 68px;
    line-height: 0.8;
  }

  @include mq(lg) {
    font-size: 72px;
  }
}

// Quotes
// --------------------------------------------------------------------------------- //

// Blockquotes:
blockquote {
  position: relative;
  margin-bottom: $spacing;

  @include mq(md) {
    margin-bottom: $spacing*2;
  }

  @include mq(lg) {
    margin-bottom: $spacing*3;
  }

  p {
    position: relative;
    @include font-size($font-size--large);
    font-weight: 700;
    margin-bottom: $spacing;

    &:before {
      content: '«' !important;
    }
    &:after {
      content: '»' !important;
    }

    padding: 0 0 0 $spacing;

    @include mq(md) {
      padding: 0 0 0 $spacing*2;

    }

    // TODO: style blockquote like magazine

    &:before {
      content: '';
    }
  }

  cite {
    display: block;
    padding-left: $spacing;
    font-style: normal;

    @include mq(md) {
      padding-left: $spacing*2;
    }
  }

  // Vertical striped line. Will fall back to vertical red line on older browsers
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -$spacing;

    width: $spacing;
    height: 100%;

    background-image: linear-gradient($color-light 70%, transparent 30%);
    background-size: 9px 9px;
  }
}

// Links
// --------------------------------------------------------------------------------- //

a {
  color: $color-neutral--darkest;

  &:hover,
  &:focus {

  }
}

p {
  & > a {
    border-bottom: 1px solid $color-neutral--darkest;

    &:hover,
    &:focus {
      color: $color-nnkm-blue;
      border-bottom-color: $color-nnkm-blue;
    }

    @include mq(md) {
      border-bottom-width: 2px;
    }
  }
}

// Generic type classes
// --------------------------------------------------------------------------------- //

.text--uppercase {
  text-transform: uppercase;
}

.text--lowercase {
  text-transform: none;
}

.text--smallcaps {
  font-variant: small-caps;
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}

.text--light {
  color: $color-light;
}

.text--hidden {
  opacity: 0;
}

// Selection - just for fun
::selection {
  background-color: $color-neutral--light;
}
