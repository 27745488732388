.panel-sami-daida-museum {
  .pane-title {
    color: $color-nnkm-yellow;
    font-weight: 900;
  }
  .panel-pane {
    padding-bottom: $spacing;

    @include mq(lg) {
      padding-bottom: $spacing--large;
    }
  }
}