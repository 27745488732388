.view-events-frontpage-block {
  margin: 0 0 0.714em 0;

  .date-nav-wrapper {
    border-bottom: 2px solid #e4e4e4;
  }
  .view-content {
    background: $color-light;
    overflow: hidden;
    padding: $spacing $spacing;

    @include mq(md) {
      width: 65%;
    }
  }
  .views-row {
    float: left;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 15px;
    padding: 0.429em 0 1.429em 0;
    width: 100%;

    &.views-row-last {
      border-bottom: 0;
    }

    .views-field-time {
      float: left;
      margin-right: 1em;
      overflow: hidden;
      width: 45px;

      .views-date-daynumber {
        color: #222222;
        display: block;
        @include font-size($font-size--medium);
        line-height: normal;
        font-weight: 300;
        margin: 0;
        text-align: center;
      }
      .views-date-monthname {
        color: #222222;
        display: block;
        @include font-size($font-size--small);
        font-weight: 700;
        line-height: normal;
        margin: -3px 0 0;
        text-align: center;
        text-transform: uppercase;
      }
    }
    .views-date-time {
      background: url("../../nnkm/images/icon-hours-small.png") no-repeat 0 0;
      color: #222222;
      display: inline-block;
      @include font-size($font-size--xsmall);
      font-weight: 700;
      line-height: 19px;
      margin: 0 0.25em 0 0;
      padding: 0 0 0 24px;
    }
    .views-field-body {
      overflow: hidden;
      position: relative;

      a {
        @include font-size($font-size--small);
      }
      p {
        margin: 0;
      }
    }
  }
  .more-link {
    display: none;
  }
}