.sidebar-pane-title {
  background-color: #3a3b3e;
  color: #fff;
  display: block;
  @include font-size($font-size--xxsmall);
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: 1.938em;
  margin: 0;
  padding: 0.750em 0 0.438em 1.429em;
  text-transform: uppercase;
}

.sidebar-pane-content {
  background: #ffffff;
  border-bottom: 2px solid #dcdcdc;
  @include font-size($font-size--xxxsmall);
  line-height: 1.500em;
  margin: 0 0 1.125em 0;
  padding: 1.250em 1.429em 1.000em 1.429em;
}