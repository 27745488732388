ul, ol {
    @include font-size($font-size--small);
    font-family: $font-primary;
}

// default unordered list
ul {

	li {

	}

}

// default numbered list
ol {
	counter-reset: li;
    list-style-type: decimal;

    li {
        position: relative;
        margin-bottom: $spacing/2;

        // Number counter
        &:before {
            content: counter(li)'.';
            counter-increment: li;

            float: left;
			margin-right: $spacing/2;

            font-family: $font-primary;
			font-weight: 700;
            @include font-size($font-size--small);
		}
	}
}
