.page-footer {
  @extend .clearfix;
  background: $color-nnkm-green;
  border-top: $border-width solid $color-nnkm-yellow;
  padding-bottom: $spacing--large;
  padding-top: $spacing;
}

.page-footer-left {
  color: $color-nnkm-yellow;
  float: left;
  width: 60%;

  @include mq(lg) {
    width: 40%;
  }
}

.page-footer-right {
  float: right;
  padding-top: 5px;
}

.page-footer__slogan {
  @include font-size($font-size--xsmall);

  &:before {
    content: '';
    @extend .icon-footer__x;
    margin-top: 2px;
  }
}
