.field-name-field-exhibition-contactperson {
  .field-collection-view {
    margin: 0 0 -0.25em 0;
    padding: 0.133em 0 0 0;
  }
  .field-items .field-item {
    margin-bottom: 0.25em;
    padding-bottom: 0.25em;
  }
  .field-label {
    font-weight: normal;
    width: 88px;
  }
}

.field-name-field-contactperson-name {
  font-weight:700;
}