/*------------------------------------*\
    #CLEARFIX
\*------------------------------------*/
/**
 * Micro clearfix, as per: nicolasgallagher.com/micro-clearfix-hack
 * Extend the clearfix class with Sass to avoid the `.clearfix` class appearing over
 * and over in your markup.
 */
.clearfix {
    zoom: 1;

    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}