@mixin icon-facebook($fillColor) {
  background-image: url(svg-encode('<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 20.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="12.4px" height="12.4px" viewBox="0 0 12.4 12.4" enable-background="new 0 0 12.4 12.4" xml:space="preserve">
<g>
	<g>
		<g>
			<path fill="' + $fillColor + '" d="M11.2,0H1.1C0.5,0,0,0.5,0,1.2v10.1c0,0.6,0.5,1.2,1.2,1.2h10.1c0.6,0,1.2-0.5,1.2-1.2V1.2
				C12.4,0.5,11.9,0,11.2,0z M10.6,3.1H9.3C9,3.1,8.7,3.3,8.7,3.6v1.3h1.8v1.9H8.7v4.3H6.8V6.8H5.5V4.9h1.2V3.6
				c0-1.4,1.1-2.5,2.5-2.5h1.3V3.1z"/>
		</g>
	</g>
</g>
</svg>'));
}

@mixin icon-instagram($fillColor) {
  background-image: url(svg-encode('<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="10.1px" height="12.4px" viewBox="0 0 10.1 12.4" enable-background="new 0 0 10.1 12.4" xml:space="preserve">
<g>
	<g>
		<g>
			<path fill="' + $fillColor + '" d="M10.1,0H0c-0.6,0-1.2,0.5-1.2,1.2V3h4.1C3.6,2.6,4.3,2.4,5,2.4S6.5,2.6,7.1,3h4.1V1.2
				C11.3,0.5,10.7,0,10.1,0z M9.9,2.3H8.5V1h1.4V2.3z"/>
			<path fill="' + $fillColor + '" d="M7.8,3.4c0.8,0.7,1.3,1.8,1.3,2.9c0,2.2-1.8,4-4,4c-2.2,0-4-1.8-4-4c0-1.2,0.5-2.2,1.3-2.9h-3.5v7.8
				c0,0.6,0.5,1.2,1.2,1.2h10.1c0.6,0,1.2-0.5,1.2-1.2V3.4H7.8z"/>
			<path fill="' + $fillColor + '" d="M5,3C3.2,3,1.6,4.5,1.6,6.4c0,1.9,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4C8.5,4.5,6.9,3,5,3z M5,9.2
				C3.5,9.2,2.2,8,2.2,6.4c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9C7.9,8,6.6,9.2,5,9.2z"/>
		</g>
	</g>
</g>
</svg>
'));
}

@mixin icon-x($fillColor) {
  background-image: url(svg-encode('<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.93 30.93"><defs><style>.cls-1{fill:' + $fillColor + ';}</style></defs><title>Unbenannt-2</title><rect class="cls-1" x="-3.19" y="12.27" width="37.35" height="6.39" transform="translate(-6.42 15.48) rotate(-45)"/><rect class="cls-1" x="12.29" y="-3.21" width="6.39" height="37.35" transform="translate(-6.42 15.48) rotate(-45)"/></svg>
'));
}