.view-exhibitions-page {
  .views-row {
    margin: 0 0 1.250em 0;
    position: relative;

    .views-field-field-exhibition-list-image {
      img {
        display: block;
        /* Upscale images in doubt to take the full width */
        width: 100%;
      }
    }
    .views-field-exhibition-upcomming {
      color: #cdcdcd;
      text-transform: uppercase;
    }
    .views-field-title {
      background: #fff;
      border-bottom: 2px solid #e2e2e2;
      bottom: 0;
      padding: 1em 1.250em;

      @include mq(lg) {
        max-width: 470px;
        position: absolute;
      }

      h2 {
        @include font-size($font-size--large);
        font-weight: 300;
        line-height: 1.167em;
        margin: 0 0 0.167em;
        padding: 0;
      }
      h3 {
        @include font-size($font-size--medium);
        font-weight: bold;
        line-height: normal;
        margin: 0;
        padding: 0;
      }
    }
  }
}

.view-sami-first-exhibition {
  padding: $spacing 0 $spacing--large;

  @include mq(lg) {
    padding: $spacing--large 0 $spacing--xlarge;
  }

  .views-field-title {
    @include font-size($font-size--xxxlarge);
    color: $color-nnkm-yellow;
    display: block;
    font-weight: 900;
    line-height: 1em;
    text-transform: uppercase;
    margin: 0;
    max-width: 300px;

    a {
      color: inherit;
    }
  }
  .views-field-field-exhibition-date {
    @include font-size($font-size--xxlarge);
    color: $color-nnkm-blue;
    font-weight: 900;
    line-height: 1em;

    a {
      color: inherit;
    }
  }
}
