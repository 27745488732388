.row {
    @extend .clearfix;
    position: relative;

    margin-left: -($spacing);
    margin-right: -($spacing);

    [class^="col-"] {
        padding-left: $spacing;
        padding-right: $spacing;
    }

    &--tight {
        margin-left: -($spacing--small);
        margin-right: -($spacing--small);

        [class^="col-"] {
            padding-left: $spacing--small;
            padding-right: $spacing--small;
        }
    }

    &--wide {
        @include mq(lg) {
            margin-left: -($spacing--large);
            margin-right: -($spacing--large);

            [class^="col-"] {
                padding-left: $spacing--large;
                padding-right: $spacing--large;
            }
        }
    }
}