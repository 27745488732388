//  MEDIA QUERIES (Includes fix for < IE9)
//  --------------------------------------------------------

$fix-mqs : false !default;
$old-ie  : false !default;

// For fixing IE9 and below: strip out media queries so everything is applied, overwriting mobile styles
@mixin old-ie {
    @if $old-ie {
        @content;
    }
}

// Min width media queries
@mixin mq($name) {
    @if $fix-mqs {
        @if $fix-mqs >= map-get($breakpoints, $name) {
            @content;
        }
    }
    @else {
        @if not map-has-key($breakpoints, $name) {
            @warn "Warning: `#{$name}` is not a valid breakpoint name, get your shit together!";
        }
        @else {
            @media (min-width: map-get($breakpoints, $name)) {
                @content;
            }
        }
    }
}

// Use rarely, if at all: Max width media queries
@mixin mq-max($name) {
    @if $fix-mqs {
        @if $fix-mqs >= map-get($breakpoints, $name) {
            @content;
        }
    }
    @else {
        @if not map-has-key($breakpoints, $name) {
            @warn "Warning: `#{$name}` is not a valid breakpoint name, get your shit together!";
        }
        @else {
            @media (max-width: map-get($breakpoints, $name)) {
                @content;
            }
        }
    }
}