// Floating
.float--right,
[class*="col-"].float--right { // To make the selector more accurate than .col- with float left
    float: right;

    &--md {
        @include mq(md) {
            float: right;
        }
    }
    &--lg {
        @include mq(lg) {
            float: right;
        }
    }
    &--xl {
        @include mq(lg) {
            float: right;
        }
    }
}
.float--left {
    float: left;
}

// Hidden until angular loads
[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}

.collapsible {
    @extend .clearfix;
    display: none;

    &.is-expanded {
        display: block;
    }

    li {
		list-style: disc;
	}
}

.bg-color {

    &--light {
        background-color: $color-light;
    }
}


// Hide stuff based on screensize
.is-hidden {
    display: none;

    &--md {
        @include mq(md) {
            display: none;
        }
    }
    &--lg {
        @include mq(lg) {
            display: none;
        }
    }
    &--xl {
        @include mq(lg) {
            display: none;
        }
    }
}

// Show stuff based on screensize
.is-visible {
    display: inherit;

    &--md {
        display: none;

        @include mq(md) {
            display: inherit;
        }
    }
    &--lg {
        display: none;

        @include mq(lg) {
            display: inherit;
        }
    }
    &--xl {
        display: none;

        @include mq(lg) {
            display: inherit;
        }
    }
}

// No selecting of text
.user-select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none; // IE10+
       -o-user-select: none;
          user-select: none;
}