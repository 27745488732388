/*------------------------------------*\
    #LAYOUT
\*------------------------------------*/

/**
 * Usage: col-{1-12}
 */

@for $i from 1 through 12 {
    .col-#{$i} {
        float: left;
        position: relative;
        width: percentage($i/12);
    }
}
@include mq(md) {

    @for $i from 1 through 12 {
        .col-#{$i}--md  {
            float: left;
            position: relative;
            width: percentage($i/12);
        }
    }
}

@include mq(lg) {

    @for $i from 1 through 12 {
        .col-#{$i}--lg  {
            float: left;
            position: relative;
            width: percentage($i/12);
        }
    }
}

@include mq(xl) {

    @for $i from 1 through 12 {
        .col-#{$i}--xl  {
            float: left;
            position: relative;
            width: percentage($i/12);
        }
    }
}

/*------------------------------------*\
    push (indent left side)
\*------------------------------------*/

/**
 * Usage: col-push-{1-11}
 */

@for $i from 1 through 11 {
    .col-push-#{$i} {
        margin-left: percentage($i/12);
    }
}

@include mq(md) {

    @for $i from 1 through 11 {
        .col-push-#{$i}--md  {
            margin-left: percentage($i/12);
        }
    }
}
@include mq(lg) {

    @for $i from 1 through 12 {
        .col-push-#{$i}--lg  {
            margin-left: percentage($i/12);
        }
    }
}
@include mq(lg) {

    @for $i from 1 through 11 {
        .col-push-#{$i}--xl  {
            margin-left: percentage($i/12);
        }
    }
}

/*------------------------------------*\
    pull (indent right side)
\*------------------------------------*/
/**
 * Usage: col-pull-{1-11}
 */
@for $i from 1 through 11 {
    .col-pull-#{$i} {
        margin-right: percentage($i/12);
    }
}

@include mq(md) {

    @for $i from 1 through 11 {
        .col-pull-#{$i}--md  {
            margin-right: percentage($i/12);
        }
    }
}
@include mq(lg) {

    @for $i from 1 through 12 {
        .col-pull-#{$i}--lg  {
            margin-right: percentage($i/12);
        }
    }
}
@include mq(lg) {

    @for $i from 1 through 11 {
        .col-pull-#{$i}--xl  {
            margin-right: percentage($i/12);
        }
    }
}


/*
    Common column combinations:
*/

.col-text-center {
    @include mq(md) {

    }

    @extend .col-12;

    @extend .col-8--md;
    @extend .col-push-2--md;
    @extend .col-pull-2--md;

    @extend .col-6--lg;
    @extend .col-push-3--lg;
    @extend .col-pull-3--lg;
}

.col-overflow {
    @extend .col-12;

    @extend .col-10--md;
    @extend .col-push-1--md;
    @extend .col-pull-1--md;

    @extend .col-8--lg;
    @extend .col-push-2--lg;
    @extend .col-pull-2--lg;

    @include mq(md) {
        // padding: 0 20px;
    }
}

.col-full-window {
    width: 100%;
}