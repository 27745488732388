/**
 * Tabs.
 */

/* Basic positioning styles shared by primary and secondary tabs. */
%tabs {
  @extend .clearfix;
  list-style: none;
  border-bottom: 1px solid #bbb;
  font-size: 16px;
  margin: 1.5em 0;
  padding: 0 2px;
  white-space: nowrap;
}

%tabs__tab {
  float: left;
  margin: 0 3px;
}

%tabs__tab-link {
  border: 1px solid #e9e9e9;
  border-right: 0;
  border-bottom: 0;
  display: block;
  text-decoration: none;
}

/* Primary tabs. */
.tabs-primary {
  @extend %tabs;
}

.tabs-primary__tab {
  @extend %tabs__tab;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid #bbb;
  border-bottom-color: transparent;
}

.tabs-primary__tab.is-active {
  @extend .tabs-primary__tab;
}

// We use 3 placeholder styles to prevent @extend from going selector crazy.
%tabs-primary__tab-link {
  @extend %tabs__tab-link;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: background-color 0.3s;
  color: #333;
  background-color: #dedede;
  letter-spacing: 1px;
  padding: 0 1em;
  text-align: center;
}

%tabs-primary__tab-link-is-hover {
  background-color: #e9e9e9;
  border-color: #f2f2f2;
}

%tabs-primary__tab-link-is-active {
  background-color: transparent;
  background-image: linear-gradient(#e9e9e9, #B0B0B0);
  border-color: #fff;
}

a.tabs-primary__tab-link {
  @extend %tabs-primary__tab-link;

  &:hover,
  &:focus {
    @extend %tabs-primary__tab-link-is-hover;
  }
  &:active {
    @extend %tabs-primary__tab-link-is-active;
  }
}

a.tabs-primary__tab-link.is-active {
  @extend %tabs-primary__tab-link;
  @extend %tabs-primary__tab-link-is-active;
}

/* Secondary tabs. */
.tabs-secondary {
  @extend %tabs;
  font-size: .9em;
  /* Collapse bottom margin of ul.primary. */
  margin-top: -1.5em;
}

.tabs-secondary__tab,
.tabs-secondary__tab.is-active {
  @extend %tabs__tab;
  margin: 0.75em 3px;
}

// We use 3 placeholder styles to prevent @extend from going selector crazy.
%tabs-secondary__tab-link {
  @extend %tabs__tab-link;
  border-radius: 0.75em;
  transition: background-color 0.3s;
  background-color: #f2f2f2;
  color: #666;
  padding: 0 .5em;
}

%tabs-secondary__tab-link-is-focus {
  background-color: #dedede;
  border-color: #999;
  color: #333;
}

%tabs-secondary__tab-link-is-active {
  background-color: #666;
  border-color: #000;
  color: #fff;
}

a.tabs-secondary__tab-link {
  @extend %tabs-secondary__tab-link;

  &:hover,
  &:focus {
    @extend %tabs-secondary__tab-link-is-focus;
  }
  &:active {
    @extend %tabs-secondary__tab-link-is-active;
  }
}

a.tabs-secondary__tab-link.is-active {
  @extend %tabs-secondary__tab-link;
  @extend %tabs-secondary__tab-link-is-active;
}
