.page-body {
  @extend .clearfix;
  padding-bottom: $spacing--large;
  top: 25px;
  position: relative;
  z-index: 1;

  @include mq(lg) {
    padding-bottom: $spacing--xlarge;
    top: 25px;
  }

  .content-scroll & {
    @include mq(lg) {
      overflow-y: scroll;
      height: 400px;
    }
  }
}
