.icon-footer {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  height: 30px;
  width: 30px;
  float: left;
  margin-left: $spacing;
  font-size: 0;
  text-indent: -100000px;

  &__facebook {
    @include icon-facebook($color-nnkm-yellow);
  }
  &__instagram {
    @include icon-instagram($color-nnkm-yellow);
  }
}

.icon-footer__x {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 60px 60px;
  height: 60px;
  width: 60px;
  float: left;
  margin-right: $spacing--small;
  @include icon-x($color-nnkm-yellow);
}