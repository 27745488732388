.panel-exhibition-detail {
  .panel-col-left {
    @include mq(lg) {
      box-sizing: border-box;
      float: left;
      width: 58%;
    }

    .pane-node-field-bildergalerie, .pane-node-field-image-gallery {
      @extend .bildergalerie-box;
    }
  }
  .panel-col-right {
    @include mq(lg) {
      box-sizing: border-box;
      float: right;
      width: 33%;
    }

    .pane-title {
      @extend .sidebar-pane-title;
    }
    .pane-content {
      @extend .sidebar-pane-content;
    }
  }
  .pane-node-field-exhibition-header-image {
    padding-bottom: $spacing;
  }
  .pane-page-title {
    color: $color-nnkm-yellow;
    padding-bottom: 0;
  }
  .field-name-field-exhibition-date {
    color: $color-light;
  }
}