.messages {
  margin: 25px 0 10px;
  padding: 10px 40px;
  background-image: url("../images/optimized/message-24-ok.png");
  background-position: 8px 12px;
  background-repeat: no-repeat;
  border: 1px solid #be7;
}

.messages--status {
  @extend .messages;
  @extend %ok;
}

.messages--warning {
  @extend .messages;
  @extend %warning;
  background-image: url("../images/optimized/message-24-warning.png");
  border-color: #ed5;
}

.messages--error {
  @extend .messages;
  @extend %error;
  background-image: url("../images/optimized/message-24-error.png");
  border-color: #ed541d;

}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

/* Core/module installation error messages. */
.messages--error p.error {
  color: #333;
}

/* System status report. */
.ok,
%ok {
  background-color: #f8fff0;
  color: #234600;
}

.warning,
%warning {
  background-color: #fffce5;
  color: #840;
}

.error,
%error {
  background-color: #fef5f1;
  color: #8c2e0b;
}