.page-header {
  @extend .clearfix;
  background: $color-nnkm-green;
  border-bottom: $border-width solid $color-nnkm-yellow;
  padding-bottom: $spacing;
  padding-top: $spacing--large;
}

.page-header__top {
  @include mq(lg) {
    float: left;
  }
}

.page-header__nav {
  @include font-size($font-size--small);

  @include mq(lg) {
    float: right;
    padding-top: 5px;
  }
}

.site-header__name {
  color: $color-nnkm-yellow;
  font-size: 32px;
  display: inline-block;
  font-weight: 900;
  line-height: 1em;

  sup {
    font-size: 0.66em;
  }
}
