// Centered page container element
.grid {
    @extend .clearfix;
    position: relative;

    max-width: $grid-width;
    margin: 0 auto;
    padding: 0 $spacing/2;

    @include mq(md) {
        padding: 0 $spacing;
    }
}