.pad-x {
    padding-left: $spacing;
    padding-right: $spacing;

    &--2 {
        padding-left: $spacing*2;
        padding-right: $spacing*2;
    }

    &--3 {
        padding-left: $spacing*3;
        padding-right: $spacing*3;
    }
    &--4 {
        padding-left: $spacing*4;
        padding-right: $spacing*4;
    }
}

.pad-x--md {
    @include mq(md) {
        padding-left: $spacing;
        padding-right: $spacing;

        &--2 {
            padding-left: $spacing*2;
            padding-right: $spacing*2;
        }

        &--3 {
            padding-left: $spacing*3;
            padding-right: $spacing*3;
        }
        &--4 {
            padding-left: $spacing*4;
            padding-right: $spacing*4;
        }
    }
}

.pad-x--lg {
    @include mq(lg) {
        padding-left: $spacing;
        padding-right: $spacing;

        &--0 {
            padding-left: 0;
            padding-right: 0;
        }

        &--2 {
            padding-left: $spacing*2;
            padding-right: $spacing*2;
        }

        &--3 {
            padding-left: $spacing*3;
            padding-right: $spacing*3;
        }
        &--4 {
            padding-left: $spacing*4;
            padding-right: $spacing*4;
        }
    }
}

.pad-y {
    padding-top: $spacing;
    padding-bottom: $spacing;

    &--double {
        padding-left: $spacing*2;
        padding-right: $spacing*2;
    }
}

.pad-y--md {
    @include mq(md) {
        padding-top: $spacing;

        &--0 {
            padding-top: 0;
        }

        &--2 {
            padding-top: $spacing*2;
        }

        &--3 {
            padding-top: $spacing*3;
        }
        &--4 {
            padding-top: $spacing*4;
        }
    }
}

.pad-y--lg {
    @include mq(lg) {
        padding-top: $spacing;

        &--0 {
            padding-top: 0;
        }

        &--2 {
            padding-top: $spacing*2;
        }

        &--3 {
            padding-top: $spacing*3;
        }
        &--4 {
            padding-top: $spacing*4;
        }
    }
}