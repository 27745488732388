@charset "UTF-8";
/*------------------------------------*\
    #SETUP
\*------------------------------------*/
/**
 * Settings, variables and tools.
 */
@import "1__settings/all";



/*------------------------------------*\
    #GENERIC
\*------------------------------------*/
/**
 * These are far reaching styles that affect and underpin every element on the
 * page, e.g. `* {}`.
 */
@import "2__generic/all";



/*------------------------------------*\
    #BASE
\*------------------------------------*/
/**
 * These are base HTML elements, things with no classes on them, e.g. `h1 {}`,
 * `blockquote {}`.
 */
@import "3__base/all";



/*------------------------------------*\
    #OBJECTS
\*------------------------------------*/
/**
 * These are design-free objects, heading-subions and design patterns. These create
 * constructs that you lay your designs over.
 */
@import "4__components/all";



/*------------------------------------*\
    #GUI
\*------------------------------------*/
/**
 * These are your components and modules; things like buttons, accordions,
 * carousels, etc.
 */
@import "5__trumps/all";